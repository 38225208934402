import axios from 'axios'
var qs = require('qs')

export default {

  install (Vue, options) {
    Vue.prototype.$flix_post = function (data, url) {
      if (typeof url === 'undefined') {
        options.url = options.post_url
      } else {
        options.url = options[url]
      }

      const post = axios.create({ baseURL: options.url + '/' + options.lang })

      if (typeof data.data !== 'object') {
        data.data = {}
      }

      post.post(data.url, qs.stringify(data.data)).then(function (ret) {
        if (typeof data.callback === 'function') { data.callback(ret) } else { console.log(ret) }
      })
    }
  }

}
