import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import plugin from './plugin'

Vue.config.productionTip = false;

Vue.use(plugin, {
  post_url: 'https://api.bookingflix.com',
  url: 'https://bookingflix.com',
  brand: 'Bookingflix',
  lang: 'de'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
